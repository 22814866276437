export const menuItems = [
  {
    label: 'About',
    path: '/about'
  },
  {
    label: 'Articles',
    path: '/articles'
  },
  {
    label: 'Project Home',
    path: 'https://ni4ai.org/'
  }
]
